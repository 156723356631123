import React from 'react'
import styled from 'styled-components'

import Nav from '../../components/nav'
import SEO from '../../components/seo'
import Footer from '../../components/footer'
import useHotjarScript from '../../utils/hooks/useHotjarScript'
import { Redirect } from '@reach/router'

const CloudAcademyCaseStudy = () => {
  useHotjarScript()
  return <Redirect to="/blog/cloud-academy/" />
  // return (
  //   <>
  //     <SEO
  //       title="Pulse | Keep Remote Teams Aligned No Matter When & Where"
  //       description="Pulse helps remote teams centralize important communications and stay aligned through long-form asynchronous updates around project, processes, decisions, strategy and more."
  //     />
  //     <Nav theme="light" />
  //     <Article>
  //       <H1>How Cloud Academy stays aligned at scale with Pulse</H1>
  //       <H2>
  //         We interviewed Stefano Bellasio, CEO and Founder at Cloud Academy.
  //         Stefano explained why they adopted Pulse and how people at Cloud
  //         Academy are benefiting from using Pulse.
  //       </H2>
  //       <Body>
  //         We founded Cloud Academy Inc. in 2013, in the US. We started with the
  //         idea of teaching people tech skills in a different way. Today Cloud
  //         Academy is the #1 Tech Skills Management platform, used by Fortune 500
  //         companies worldwide to assess, develop and validate their tech skills.
  //         We work primarily with enterprise organizations in the US, EMEA, and
  //         Asia but our customers are in many industries, from tech to pharma and
  //         banking. They bring to Cloud Academy their entire IT organization and
  //         take full control of their skills while developing their people.
  //       </Body>
  //       <Body>
  //         In a world where companies need to constantly build more software and
  //         digital services, Cloud Academy helps them develop and retain their
  //         tech talent, letting them build career paths, measuring their skill
  //         gaps, and running programs at scale for upskilling on cloud, software
  //         and data skills.
  //       </Body>
  //       <Body>
  //         When we started Cloud Academy in San Francisco we seriously thought we
  //         would have ended up really having just two locations: Switzerland (for
  //         our engineering team) and San Francisco.
  //       </Body>
  //       <Body>We couldn’t be more wrong!</Body>
  //       <Body>
  //         That was 2013 and by 2016 we were already onboarding people in several
  //         states across the US. We couldn’t simply find everyone we needed in
  //         San Francisco for our content team, and the same quickly happened for
  //         Sales and Customer Success. All of a sudden we found ourselves hiring
  //         people for fully remote positions. It makes me laugh thinking that we
  //         did that without even realizing it.
  //       </Body>
  //       <Body>
  //         Fast forward to today, we are getting closer to 200 people in our team
  //         that are truly globally distributed. We operate in about 7 countries,
  //         with the US, Canada and Switzerland being our biggest centers, but we
  //         have just one office and everyone else is fully remote. By the end of
  //         2021 we will likely have even more countries (and time zones!), we are
  //         adding more people in EMEA and continuing our growth in the US.
  //       </Body>
  //       <H3>
  //         How did Pulse get adopted by Cloud Academy? Can you share more about
  //         the problems that you needed to solve?
  //       </H3>
  //       <Body>
  //         When you start hiring people remotely and you pass 50 people in your
  //         organization, you need to start paying more attention to
  //         communication. Not just the CEO, everyone all of a sudden needs to
  //         keep the company informed on what’s going on. It can be that new
  //         product feature the team has been building without Sales knowing that
  //         or constant feedback our customers give us that nobody in marketing
  //         and product knows about!
  //       </Body>
  //       <Body>
  //         These episodes start small and you usually try to fix them on the fly,
  //         but at some point, they really create large issues for the entire
  //         company. I can tell you that there is nothing worse than having 50-100
  //         people not knowing what your company is doing and why.
  //       </Body>

  //       <Body>
  //         I think that it was around 2015 when we realized that we really needed
  //         to put more effort into our communication and find new ways to talk to
  //         our people. We had people in California and people in Switzerland, 9
  //         hours in between them and… a different culture. We tried Basecamp and
  //         we used it for quite a few years. Then we jumped on Slack as it became
  //         more popular.
  //       </Body>
  //       <Body>
  //         In 2019-2020 things got more complicated. We had many more enterprise
  //         customers, more demanding customers, and a lot of new people joining
  //         us across all departments. We started using Pulse because we felt the
  //         need to simply communicate more and more frequently while avoiding
  //         something “fast” and easy to forget like a Slack message.
  //       </Body>
  //       <Body>
  //         Since then we’ve been relying on Pulse to communicate with our people
  //         and it’s is improving quite a lot the visibility that everyone has in
  //         what we do and why.
  //         <br />I think the thing that I love the most is that it allows
  //         everyone to “talk” to the rest of the organization.
  //       </Body>

  //       <H3>
  //         What were your initial use cases with pulse? Can you make any
  //         examples?
  //       </H3>
  //       <Body>
  //         So, we release 700+ new courses every year in our library and about
  //         100 new features on our platform. At some point it was really a
  //         struggle to let everyone in Sales/Marketing and Customer Success
  //         follow that, asking questions on when that was going live and how to
  //         introduce that to customers. We were scheduling more and more
  //         meetings. Which is honestly what everyone does, but it was still not
  //         efficient and not scalable.
  //       </Body>
  //       <Body>
  //         A few months ago we introduced a new big feature for our Enterprise
  //         customers, a new organization system to onboard and manage their
  //         people in Cloud Academy. Our Product team used Pulse to collect
  //         feedback across the CS and Sales teams and then we kept them up to
  //         date on the migration process for all our customers. If something was
  //         wrong or not expected, they could let our product people know quickly
  //         and vice versa.
  //       </Body>
  //       <Body>
  //         Everyone in CS (in the US and UK) was constantly aware of the new
  //         batches of customers to migrate and was following the updates we were
  //         making to the product to communicate regularly with our customers.
  //       </Body>

  //       <H3>
  //         Once you brought the product in what were the first people’s
  //         reactions? How was the general response from the employees?
  //       </H3>
  //       <Body>
  //         “Another tool, really?” Joking, that is always the reaction! I think
  //         people wanted to understand its value and why spending time, again, on
  //         another tool. Once they saw they could write and update everyone
  //         quickly, in one place, they started using it more and more.
  //       </Body>
  //       <Body>
  //         At the end of the day, people like to see value in what they do and
  //         how they spend their time, I think that showing how Pulse could help
  //         them was the most important aspect of introducing it.
  //       </Body>
  //       <H3>
  //         Which are the teams that use Pulse the most right now? For which use
  //         cases?
  //       </H3>
  //       <Body>
  //         I’d say it’s pretty good. Product and Content teams are using it the
  //         most, but that’s because they are the ones that need to be closer to
  //         the rest of the organization.
  //       </Body>
  //       <Body>
  //         Most of the time it’s about new features we are building, they use it
  //         to communicate ETAs and new functionalities, but also to collect
  //         feedback across the rest of the organization.
  //       </Body>

  //       <H3>What are the most helpful features of Pulse?</H3>

  //       <Body>
  //         <strong>Streams</strong>. I love the idea of following specific areas
  //         of the company and being constantly up to date, even if I don’t work
  //         with that department very often. It builds a rhythm and it shares that
  //         with the rest of the organization.
  //       </Body>

  //       <H3>
  //         What are the other internal tools that you use to communicate at Cloud
  //         Academy along with Pulse?
  //       </H3>
  //       <Body>
  //         Slack is definitely the main one for us together with emails.
  //       </Body>

  //       <H3>Who would you recommend Pulse to?</H3>
  //       <Body>
  //         I think that a company of our size and above does really need
  //         something like Pulse. If you are remote you need something to keep
  //         everyone aligned, and if your people are in different time zones and
  //         from different cultures, you truly appreciate the difference it makes.
  //       </Body>
  //       <Body>
  //         I would suggest it also to companies where there are multiple teams
  //         distributed, like having your Product team or Sales team in multiple
  //         locations. Improving internal communication is a game-changer for the
  //         business and so it is asking everyone to start using Pulse more.
  //       </Body>

  //       <H3>
  //         For which companies do you think it works? For which it doesn’t?
  //       </H3>
  //       <Body>
  //         Clearly distributed and global companies are the best fit for
  //         something like Pulse. It’s really the basics of what you need to do to
  //         keep everyone aligned. I personally think that every company, even
  //         with people on the same floor or office, needs to use something like
  //         this once they pass 50 people.
  //       </Body>
  //       <Body>
  //         If you either don't have a strong written culture or you don't want to
  //         have one at all, then Pulse isn't the tool you need.
  //       </Body>
  //       <Body>
  //         On the other side, if you're truly looking to level up your company
  //         alignment, create a remote-friendly culture and enable your people to
  //         do their best work by giving them the info they need, then Pulse is
  //         the obvious choice.
  //       </Body>
  //     </Article>
  //     <Footer />
  //   </>
  // )
}

const Article = styled.article`
  width: 43.75rem;
  max-width: 95vw;
  margin: auto;
  padding-top: 2rem;
`

const H1 = styled.h1`
  font-family: ${props => props.theme.typography.fontFamilyGTBold};
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 2.6875rem;
  letter-spacing: 0.0625rem;
`

const H2 = styled.h2`
  font-family: ${props => props.theme.typography.fontFamilyGTBold};
  font-size: 1rem;
  line-height: 1.75rem;
  color: rgb(117, 117, 117);
  margin: 1.5rem 0rem;
`

const H3 = styled.h3`
  font-family: ${props => props.theme.typography.fontFamilyGTBold};
  font-weight: bold;
  font-size: 1.75rem;
  line-height: 2.125rem;
  color: #000000;
  margin: 2rem 0rem;
`

const Body = styled.p`
  font-family: ${props => props.theme.typography.fontFamilyInter};
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 2.125rem;
  color: #545454;
  margin: 2rem 0rem;
`

export default CloudAcademyCaseStudy
